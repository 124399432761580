import React from 'react';
import {Switch, Card, TimePicker, Icon} from 'antd';
import moment from 'moment';

import './base-day.css';

export default class BaseDay extends React.Component {
    getDate = (date) => {
        const time = date.split(':');
        return moment().set({hour: time[0], minute: time[1]});
    };

    handleStartTime = (time,i) => {
        this.props.handleOpens(`${moment(time).format('HH')}:${moment(time).format('mm')}`, i);
    };

    handleEndTime = (time,i) => {
        this.props.handleCloses(`${moment(time).format('HH')}:${moment(time).format('mm')}`, i);
    };

    render() {
        const { timeDetails } = this.props;
        return (
            <div className="base-day-card">
                <Card style={{width: 300, marginTop: 16}}>
                    <div className="base-day-header">
                        <div className="day-of-week">
                            {timeDetails.day_of_week}
                        </div>
                        <div className="day-status">
                            <span>Disabled</span> <Switch checked={timeDetails.enabled && !!timeDetails.time_periods.length} onChange={this.props.handleEnable}/> <span>Enabled</span>
                        </div>
                    </div>
                    { timeDetails.time_periods.map((time, index) => (
                        <React.Fragment key={index}>
                        <div className="base-comp">
                            <div>
                            <div className="base-ticket">
                                <span>Opens at</span>
                                <TimePicker use24Hours format="h:mm a" minuteStep={5} value={this.getDate(time.start_time)}
                                            onChange={(t) => this.handleStartTime(t,index)}/>
                            </div>

                            <div className="base-ticket">
                                <span>Closes at</span>
                                <TimePicker use24Hours format="h:mm a" minuteStep={5} value={this.getDate(time.end_time)}
                                            onChange={(t) => this.handleEndTime(t,index)}/>
                            </div></div>
                            <div className="icon-minus"><Icon type="minus-circle" onClick={() => this.props.handleRemove(index)}/></div>
                        </div>
                        <hr/>
                        </React.Fragment>
                    ))}
                    <div className="add-block-time" onClick={this.props.addBlock}><Icon type="plus-circle" />&nbsp; <p> Add block of time</p></div>
                </Card>
            </div>
        )
    }
}
