import React from 'react';
import { Modal } from 'antd';
import './details-modal.css';

export default class UberEats extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        const { visible, messages, handleToggle, handleClearMessage } = this.props;
        return(
            <Modal
                title="UberEats Live Update Stores"
                visible={visible}
                onOk={handleToggle}
                onCancel={handleToggle}
                afterClose={handleClearMessage}
            >
                <React.Fragment>
                    {messages.map((m, index) => (
                        <p className={m.type} key={index}>{m.message}</p>
                    ))}
                </React.Fragment>
            </Modal>
        )
    }
}