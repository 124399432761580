export const dayTime = {
    days_hours_base: {
        service_availability: [{
            enabled: false,
            time_periods: [],
            day_of_week: "monday"
        },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "tuesday"
            },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "wednesday"
            },
            {
                "enabled": false,
                "time_periods": [],
                day_of_week: "thursday"
            },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "friday"
            },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "saturday"
            },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "sunday"
            }
        ],
        subtitle: "10:00 AM – 9:45 PM"
    },
    days_hours_breakfast: {
        service_availability: [{
            enabled: false,
            time_periods: [],
            day_of_week: "monday"
        },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "tuesday"
            },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "wednesday"
            },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "thursday"
            },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "friday"
            },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "saturday"
            },
            {
                enabled: false,
                time_periods: [],
                day_of_week: "sunday"
            }
        ],
        subtitle: "10:00 AM – 9:45 PM"
    },
    uuid: "",
    menu_id: "",
    name: "",
    has_breakfast: false,
    state: ""
}