import React from 'react';
import {Radio, Switch, notification, Select, Button} from 'antd';
import baseService from '../../services/baseService';
import BaseDayNew from '../../common/BaseDayNew';
import {dayTime} from '../../components/CreateStore/dayTime';
import Loader from '../Loader';
import './new-store.css';

const RadioGroup = Radio.Group;
const Option = Select.Option;

export default class NewStore extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuList: [],
            selectedMenuName: '',
            selectedMenu: '',
            hasBreakfast: false,
            dayTimeData: {...dayTime},
            stores: [],
            selected: '',
            loading: false,
        };
    }

    componentWillMount() {
        this.getMenuLists();
        this.getStoreList();
    }

    getStoreList = () => {
        baseService({
            url: '/stores/storesList',
            method: 'get'
        }).then((response) => {
            this.setState({loading: false, stores: response.data});
        }).catch((error) => console.log(error))
    };

    onChange = (e) => {
        const menu = this.state.menuList.find((menu) => e.target.value === menu.menu_id);
        this.setState({selectedMenu: e.target.value, selectedMenuName: menu && menu.menu_name || ''});
    };

    getMenuLists = () => {
        this.setState({loading: true});
        baseService({
            url: '/menus/menusList',
            method: 'get'
        }).then((menus) => {
            if (menus.data.length) {
                this.setState({
                    menuList: menus.data,
                    selectedMenu: menus.data[0].menu_id,
                    selectedMenuName: menus.data[0].menu_name,
                    loading: false
                });
            }
        }).catch((error) => {
            this.setState({loading: false});
            alert(error);
        });
    };

    handleEnable = (val, position, days) => {
        let {dayTimeData} = this.state;
        if (dayTimeData[days].service_availability[position].time_periods.length) {
            dayTimeData[days].service_availability[position].enabled = val;
            this.setState({dayTimeData});
        }
    };

    addBlock = (i, days) => {
        const {dayTimeData} = this.state;
        const availability = dayTimeData[days].service_availability[i].time_periods;
        let blockToAdd = {
            start_time: '00:00',
            end_time: '00:00'
        };
        if (availability.length) {
            blockToAdd = availability[availability.length - 1]
        }
        dayTimeData[days].service_availability[i].time_periods.push(blockToAdd);
        this.setState({storeDetails: dayTimeData});
    };

    handleOpens = (startTime, pos, position, days) => {
        let {dayTimeData} = this.state;
        dayTimeData[days].service_availability[position]['time_periods'][pos]
            = {...dayTimeData[days].service_availability[position]['time_periods'][pos], start_time: startTime};
        this.setState({dayTimeData});
    };

    handleCloses = (endTime, pos, position, days) => {
        let {dayTimeData} = this.state;
        dayTimeData[days].service_availability[position]['time_periods'][pos]
            = {...dayTimeData[days].service_availability[position]['time_periods'][pos], end_time: endTime};
        this.setState({dayTimeData});
    };

    handleSubmit = () => {
        const {dayTimeData} = this.state;
        this.setState({loading: true});
        if (!dayTimeData.has_breakfast) {
            delete dayTimeData.days_hours_breakfast;
        }
        this.addStore(dayTimeData);
    };

    addStore = (editDay) => {
        editDay = {
            ...editDay,
            menu_id: this.state.selectedMenu,
        };
        this.setState({loading: true});
        baseService({
            url: '/stores/addStore',
            method: 'post',
            data: editDay
        }).then(() => {
            this.handleClear();
            notification['success']({
                message: 'Store added successfully!',
                description: '',
            });
        }).catch((error) => {
            this.setState({loading: false});
            notification['error']({
                message: 'something went wrong while adding store!',
                description: '',
            });
        });
    };

    handleHasBreakfast = (val) => {
        this.setState({dayTimeData: {...this.state.dayTimeData, has_breakfast: val}})
    };

    handleRemove = (i, ii, days) => {
        const {storeDetails} = this.state;
        storeDetails[days].service_availability[ii].time_periods.splice(i, 1);
        this.setState({storeDetails: storeDetails});
    };

    handleChange = (e, key) => {
        const {dayTimeData} = this.state;
        dayTimeData[key] = e.target.value;
        this.setState({dayTimeData});
    };

    handleClear = () => {
        const refreshData = {
            days_hours_base: {
                service_availability: [
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "monday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "tuesday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "wednesday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "thursday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "friday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "saturday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "sunday"
                    }
                ],
                subtitle: "10:00 AM – 9:45 PM"
            },
            days_hours_breakfast: {
                service_availability: [{
                    enabled: false,
                    time_periods: [],
                    day_of_week: "monday"
                },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "tuesday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "wednesday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "thursday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "friday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "saturday"
                    },
                    {
                        enabled: false,
                        time_periods: [],
                        day_of_week: "sunday"
                    }
                ],
                subtitle: "10:00 AM – 9:45 PM"
            },
            uuid: "",
            menu_id: "",
            name: "",
            has_breakfast: false,
            state: ""
        };
        this.setState({dayTimeData: {...refreshData}, loading: false});
    };

    render() {
        const {menuList, selectedMenu, dayTimeData, loading} = this.state;
        return (
            <div className="main-wrapper">
                <Loader isLoading={loading}/>
                <div className="new-store">
                    <h1 className="pb-1 ">Add new Store</h1>
                    <div className="col-md-6">
                        <div className="form-group row mt-4">
                            <label className="col-sm-4 col-form-label">Store Name</label>
                            <div className="col-sm-6">
                                <input type="text" className="form-control" value={dayTimeData.name}
                                       onChange={(e) => this.handleChange(e, 'name')}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">State</label>
                            <div className="col-sm-6">
                                <input type="text" className="form-control" value={dayTimeData.state}
                                       onChange={(e) => this.handleChange(e, 'state')}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">UUID(Uber Store id)</label>
                            <div className="col-sm-6">
                                <input type="text" className="form-control" value={dayTimeData.uuid}
                                       onChange={(e) => this.handleChange(e, 'uuid')}/>
                            </div>
                        </div>
                    </div>
                    <h5>Associated Menu</h5>
                    <div className="radio-menu">
                        <RadioGroup onChange={this.onChange} value={selectedMenu}>
                            {menuList.length > 0 && menuList.map((menu, index) => (
                                <Radio value={menu.menu_id} key={index}>{menu.menu_name}</Radio>
                            ))}
                        </RadioGroup>
                    </div>
                    <div className="store-toggle mt-3 d-flex justify-content-center">
                        <h5>Has Breakfast</h5>
                        <div className="day-status-o">
                            <span>False</span> <Switch checked={dayTimeData.has_breakfast}
                                                       onChange={this.handleHasBreakfast}/> <span>True</span>
                        </div>
                    </div>

                </div>
                <div className="base-modal mt-2">
                    <h4 className="mb-3 mt-4">
                        Base
                    </h4>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="base-content mb-3">
                                {dayTimeData.days_hours_base.service_availability.map((timeDetails, index) => (
                                    <BaseDayNew
                                        timeDetails={timeDetails}
                                        key={index}
                                        handleEnable={(val) => this.handleEnable(val, index, 'days_hours_base')}
                                        addBlock={() => this.addBlock(index, 'days_hours_base')}
                                        handleOpens={(startTime, pos) => this.handleOpens(startTime, pos, index, 'days_hours_base')}
                                        handleCloses={(endTime, pos) => this.handleCloses(endTime, pos, index, 'days_hours_base')}
                                        handleRemove={(i) => this.handleRemove(i, index, 'days_hours_base')}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    {dayTimeData.has_breakfast && <React.Fragment>
                        <h4 className="mb-3 mt-4">
                            Breakfast
                        </h4>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="base-content mb-3">
                                    {dayTimeData.days_hours_breakfast.service_availability.map((timeDetails, index) => (
                                        <BaseDayNew
                                            timeDetails={timeDetails}
                                            key={index}
                                            handleEnable={(val) => this.handleEnable(val, index, 'days_hours_breakfast')}
                                            addBlock={() => this.addBlock(index, 'days_hours_breakfast')}
                                            handleOpens={(startTime, pos) => this.handleOpens(startTime, pos, index, 'days_hours_breakfast')}
                                            handleCloses={(endTime, pos) => this.handleCloses(endTime, pos, index, 'days_hours_breakfast')}
                                            handleRemove={(i) => this.handleRemove(i, index, 'days_hours_breakfast')}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>}
                    <Button type="primary" onClick={this.handleSubmit}>Add New Store</Button>
                </div>
            </div>
        )
    }
}
