import React from 'react';
import { Route,Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import HeaderComponent from '../../common/Header';
import { isLoggedIn,getAccessToken } from '../../services/utils';

const { Content } = Layout;

// export const PublicRoute = ({component: Component, ...rest}) => {
//     return (
//         <div>`
//             <Layout className="layout">
//             <HeaderComponent />
//                 <Content style={{ padding: '0 50px' }}>
//                     <Route {...rest} render={(routeProps) => (
//                         <Component {...routeProps} />
//                     )}/>
//                 </Content>
//             </Layout>
//         </div>
//     )
// };

export const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        isLoggedIn() ?
        <div>
            <Layout className="layout">
                <HeaderComponent />
                <Content style={{ padding: '0 50px' }}>
                    <Route {...rest} render={(routeProps) => (
                        <Component {...routeProps} />
                    )}/>
                </Content>
            </Layout>
        </div> :
            <Redirect to='/login'/>
    )
};

export const PublicRoute = ({component: Component, ...rest}) => {
    return (
        isLoggedIn() ?
            <div>
                <Layout className="layout">
                    {/*<HeaderComponent />*/}
                    <Content style={{ padding: '0 50px' }}>
                        <Route {...rest} render={(routeProps) => (
                            <Component {...routeProps} />
                        )}/>
                    </Content>
                </Layout>
            </div> :
            <Redirect to='/login'/>
    )
};


export const LoginRoute = ({component: Component, ...rest}) => {
    return (
        !isLoggedIn() ?
                <Route {...rest} render={(routeProps) => (
                            <Component {...routeProps} />)}/>
                   :
            <Redirect to='/'/>
    )
};
