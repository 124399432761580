import React from 'react';
import { Switch, Button, Input, Popconfirm, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import history from '../../../services/history';
import { baseUrl } from '../../../services/baseService';

class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderRelease: props.settings.order_release_enabled || false,
            posIntegration: props.settings.pos_integration_enabled || false
        }
    }

    handleChange = (checked, field) => {
        this.setState({ [field]: checked });
    };

    getMenuName = (menuId) => {
        const menu = this.props.menuList.find((menus) => menus.menu_id === menuId);
        return menu && menu.menu_name || '';
    };

    confirm = () => {
        axios.post(`${baseUrl}/stores/delete`, { uuid: this.props.settings.uuid }).then(() => {
         this.props.history.push('/');
        }).catch((error) => {
            notification['error']({
                message: error.response.data.user_msg,
                description: '',
            });
        });
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            orderRelease: nextProps.settings.order_release_enabled || false
            , posIntegration: nextProps.settings.pos_integration_enabled || false
        })
    }

    render() {
        const { settings } = this.props;
        const { orderRelease, posIntegration } = this.state;
        return (
            <div className="setting-content">
                <div className="left-side">
                    <div className="setting-header">
                        <h5>Store Information</h5>
                    </div>
                    <p>Name: <span>{settings && settings.name || ''}</span></p>
                    <p>Location: <span>{settings && settings.state || ''}</span></p>
                    <p>menu-version: <span>{this.getMenuName(settings.menu_id)}</span></p>
                    <div className="btn-delete">
                    <Popconfirm placement="topLeft" title='Are you sure to delete this Store?' onConfirm={this.confirm} okText="Yes" cancelText="Cancel">
                        <Button type="secondary">Delete Store</Button>
                    </Popconfirm>
                    </div>
                </div>
                <div className="right-side">
                    <div className="integrate">
                        <p>Enable POS Integrartion: </p>
                        <div className="store-switch">
                            <span>Disabled</span>
                            <Switch checked={posIntegration} onChange={(checked) => this.handleChange(checked, 'posIntegration')} />
                            <span>Enabled</span>
                        </div>
                    </div>
                    <div className="integrate">
                        <p>Store UUID</p>
                        <div className="store-switch"><span>{settings && settings.uuid || ''}</span></div>
                    </div>
                    <div className="btn">
                        <div></div>
                        <Button type="primary">submit</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Settings)



