import React from 'react';
import {Tabs, Select, Button, notification} from 'antd';
import Base from './Base';
import Breakfast from './Breakfast';
import Settings from './Settings';
import Loader from '../Loader';
import baseService from '../../services/baseService';
import './create-store.css';

const TabPane = Tabs.TabPane;
const Option = Select.Option;

export default class CreateStore extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            storeList: [],
            storeDetails: '',
            menuList: [],
            selectedStore: '',
            loading: false,
            currentTab: "1",
        }
    }

    componentWillMount() {
        this.getStoreList();
        this.menuList();
    }

    getStoreList = () => {
        baseService({
            url: '/stores/storesList',
            method: 'get',
        }).then((stores) => {
            let store = this.props.location.state.uuid;
            if (store) {
                this.setState({storeDetails: this.props.location.state, selectedStore: store});
                this.handleStoreChange(store);
            }
            this.setState({storeList: stores.data});
        }).catch((error) => console.log(error));
    };

    menuList = () => {
        baseService({
            url: '/menus/menusList',
            method: 'get',
        }).then((menus) => {
            this.setState({menuList: menus.data});
        }).catch((error) => console.log(error));
    };

    handleStoreChange = (value) => {
        this.setState({selectedStore: value, loading: true});
        baseService({
            url: '/stores/getStore',
            method: 'post',
            data: {uuid: value}
        }).then((storeDetail) => {
            this.setState({storeDetails: storeDetail.data, loading: false})
        }).catch((error) => {
            notification['error']({
                message: error.response.data.user_msg,
                description: '',
            });
            this.setState({loading: false});
        });
    };

    onTabChanged = (tab) => {
        this.setState({currentTab: tab});
    };

    handleEnable = (val, position, days) => {
        let {storeDetails} = this.state;
        storeDetails[days].service_availability[position].enabled = val;
        this.setState({storeDetails});
    };

    handleOpens = (startTime, pos, position, days) => {
        let {storeDetails} = this.state;
        storeDetails[days].service_availability[position]['time_periods'][pos]
            = {...storeDetails[days].service_availability[position]['time_periods'][pos], start_time: startTime};
        this.setState({storeDetails});
    };

    handleCloses = (endTime, pos, position, days) => {
        let {storeDetails} = this.state;
        storeDetails[days].service_availability[position]['time_periods'][pos]
            = {...storeDetails[days].service_availability[position]['time_periods'][pos], end_time: endTime};
        this.setState({storeDetails});
    };

    handleSubmit = () => {
        const {storeDetails} = this.state;
        this.setState({loading: true});
        const editDay = {
            days_hours_base: storeDetails['days_hours_base'],
            uuid: storeDetails.uuid
        };
        if (storeDetails.has_breakfast) {
            editDay.days_hours_breakfast = storeDetails['days_hours_breakfast'];
        }
        this.editDayTime(editDay);
    };

    editDayTime = (editDay) => {
        let {currentTab, storeDetails} = this.state;
        baseService({
            url: '/stores/editDayTime',
            method: 'post',
            data: editDay
        }).then((updatedDayTime) => {
            if (updatedDayTime.data.result !== 'Already updated') {
                if (currentTab === "1") {
                    storeDetails['days_hours_base'] = updatedDayTime.data.data['days_hours_base'];
                } else {
                    storeDetails['days_hours_breakfast'] = updatedDayTime.data.data['days_hours_breakfast'];
                }
                this.setState({storeDetails, loading: false});
            } else {
                this.setState({loading: false});
            }
            notification['success']({
                message: 'Updated successfully!',
                description: '',
            });
        }).catch((error) => {
            this.setState({loading: false});
            notification['error']({
                message: 'something went wrong!',
                description: '',
            });
        });
    };

    addBlock = (i, days) => {
        const {storeDetails} = this.state;
        const availability = storeDetails[days].service_availability[i].time_periods;
        let blockToAdd = {
            start_time: '00:00',
            end_time: '00:00'
        };
        if (availability.length) {
            blockToAdd = availability[availability.length - 1]
        }
        storeDetails[days].service_availability[i].time_periods.push(blockToAdd);
        this.setState({storeDetails: storeDetails});
    };

    handleRemove = (i, ii, days) => {
        const {storeDetails} = this.state;
        storeDetails[days].service_availability[ii].time_periods.splice(i, 1);
        this.setState({storeDetails: storeDetails});
    };

    render() {
        const {storeList, storeDetails, menuList, loading, selectedStore, currentTab} = this.state;
        const children = [];
        storeList.forEach((store) => {
            children.push(<Option key={store.uuid}>{store.name}</Option>);
        });
        return (
            <div>
                <div className="header-content">
                    <div className="blank-space"></div>
                    <div className="title-store">
                        <h3>{storeDetails && storeDetails.name}</h3>
                    </div>
                    <div className="search-bar">
                        <Select
                            className="mb-3 mr-3"
                            style={{width: '90%'}}
                            placeholder="Select Stores"
                            onChange={this.handleStoreChange}
                            value={selectedStore}
                        >
                            {children}
                        </Select>
                    </div>
                </div>

                <Tabs className="tab-content" defaultActiveKey="1" onTabClick={this.onTabChanged}>
                    <TabPane tab="Base" key="1">{
                        loading ? <Loader isLoading={loading}/> :
                            <Base
                                daysBase={storeDetails.days_hours_base}
                                handleEnable={this.handleEnable}
                                handleOpens={this.handleOpens}
                                handleCloses={this.handleCloses}
                                addBlock={this.addBlock}
                                handleRemove={this.handleRemove}
                            />
                    }
                    </TabPane>
                    {storeDetails.has_breakfast &&
                    <TabPane tab="Breakfast" key="2">
                        {loading ? <Loader isLoading={loading}/> :
                            <Breakfast
                                daysBreakfast={storeDetails.days_hours_breakfast}
                                handleEnable={this.handleEnable}
                                handleOpens={this.handleOpens}
                                handleCloses={this.handleCloses}
                                addBlock={this.addBlock}
                                handleRemove={this.handleRemove}
                            />
                        }
                    </TabPane>
                    }
                    <TabPane tab="Settings" key="3">
                        {loading ? <Loader isLoading={loading}/> :
                            <Settings settings={storeDetails} menuList={menuList}/>}
                    </TabPane>
                </Tabs>
                {currentTab !== "3" && <div className="btn-submit">
                    <Button type="primary" disabled={loading} onClick={this.handleSubmit}>Save</Button>
                </div>}
            </div>
        )
    }
}

