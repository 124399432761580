import React from 'react';
import BaseDay from '../../../common/BaseDay';

export default class Base extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {daysBase} = this.props;
        return (
            <React.Fragment>
                <div className="base-tab">
                    {daysBase && daysBase.service_availability && daysBase.service_availability.length
                    && daysBase.service_availability.map((timeDetails, index) => (
                        <BaseDay
                            timeDetails={timeDetails}
                            key={index}
                            position={index}
                            handleEnable={(val) => this.props.handleEnable(val, index, 'days_hours_base')}
                            handleOpens={(startTime,pos) => this.props.handleOpens(startTime, pos, index, 'days_hours_base')}
                            handleCloses={(endTime, pos) => this.props.handleCloses(endTime, pos, index, 'days_hours_base')}
                            addBlock = {() => this.props.addBlock(index,'days_hours_base')}
                            handleRemove={(i) => this.props.handleRemove(i,index,'days_hours_base')}
                        />
                    ))}
                </div>
            </React.Fragment>
        )
    }
}


