import React from 'react';
import {Checkbox, Row, Col, Button} from 'antd';
import {groupBy} from 'lodash';
import DetailsModal from './DetailsModal';
import baseService from '../../services/baseService';
import Loader from '../Loader';

export default class UberEats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            stores: [],
            selectedStores: [],
            messages: [],
            isOpen: false
        };
    }

    componentWillMount() {
        this.getAllStores();
    }

    getAllStores() {
        this.setState({loading: true});
        baseService({
            url: '/stores/storesList',
            method: 'get',
        }).then((response) => {
            const stores = response.data.map(store => ({
                ...store,
                selected: false
            }));
            this.setState({loading: false, stores});
        }).catch((error) => console.log())
    };

    handleChecked = (event, store) => {
        const {stores} = this.state;
        const index = stores.findIndex(s => s.uuid === store.uuid);
        stores[index].selected = event.target.checked;
        this.setState({stores});
    };

    handleAllChecked = (e) => {
        let {stores} = this.state;
        stores = stores.map(store => ({
            ...store,
            selected: e.target.checked
        }));
        this.setState({stores});
    };

    assignMenuToStore = async () => {
        this.setState({isOpen: true});
        const selectedStore = this.getSelectedStore();
        this.setState({selectedStores: selectedStore});
        for (let store of selectedStore) {
            await this.setState((state) => ({
                messages: [...state.messages, {
                    type: 'update',
                    message: `Updating ${store.name} ...`
                }]
            }));
            await this.sendMenuToUber(store);
        }
    };

    async sendMenuToUber(store) {
        try {
            this.setState({loading: true});
            await baseService({
                url: '/stores/sendMenuToUber',
                method: 'post',
                data: {uuid: store.uuid}
            });
            this.setState({loading: false});
            await this.setState((state) => ({
                messages: [...state.messages, {
                    type: 'success',
                    message: `Successful update ${store.name}`
                }]
            }));
        } catch (error) {
            this.setState({loading: false});
            await this.setState((state) => ({
                messages: [...state.messages, {
                    type: 'error',
                    message: `Failed to update ${store.name} and Message : ${error.response.data.user_msg}`
                }]
            }));
        }
    }

    getSelectedStore() {
        return this.state.stores.filter(store => store.selected);
    };

    handleToggle = () => {
        this.setState((state) => ({isOpen: !state.isOpen}));
        this.handleClearMessage();
    };

    handleClearMessage = () => {
        this.setState({messages: []});
    };

    render() {
        const groupByStateAllStore = groupBy(this.state.stores, "state");
        const {messages, isOpen, loading} = this.state;
        return (
            <div className="menu-content-checkbox">
                <Loader isLoading={loading}/>
                <Checkbox onChange={this.handleAllChecked}/> Select All
                {Object.keys(groupByStateAllStore).map((key) => (
                    <div className="text-block" key={key}>
                        <h5>{key}</h5>
                        <div style={{width: '100%'}}>
                            <Row>
                                {
                                    groupByStateAllStore[key] && groupByStateAllStore[key].map((store, index) => (
                                        <Col span={8} key={index}><Checkbox checked={store.selected}
                                                                            onChange={(e) => this.handleChecked(e, store)}>{store.name}</Checkbox></Col>
                                    ))
                                }
                            </Row>
                        </div>
                    </div>
                ))}
                <div className='save-btn'>
                    <Button type="primary" onClick={this.assignMenuToStore}>Save Selection</Button>
                </div>
                <DetailsModal
                    visible={isOpen}
                    messages={messages}
                    handleToggle={this.handleToggle}
                    handleClearMessage={this.handleClearMessage}
                />
            </div>
        )
    }
}
