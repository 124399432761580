import React from 'react';
import axios from 'axios';
import {Radio, notification, Checkbox, Row, Col, Button, Input, Upload, Icon} from 'antd';
import {groupBy} from 'lodash';
import baseService, {baseUrl} from '../../services/baseService';
import Loader from '../Loader';
import {getAccessToken} from '../../services/utils';
import './menu.css';

const RadioGroup = Radio.Group;

export default class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuList: [],
            selectedMenu: '',
            list: [],
            stores: [],
            isNew: false,
            selectedMenuName: '',
            fileList: [],
            menuLoading: false,
            storeListChange: [],
            loading: false
        };
    }

    componentWillMount() {
        this.getMenuLists();
        this.getAllStores();
    }

    getAllStores() {
        this.setState({loading: true});
        baseService({
            url: '/stores/storesList',
            method: 'get',
        }).then((response) => {
            this.setState({loading: false, stores: response.data});
        }).catch((error) => console.log())
    };

    handleChangeFile = (info) => {
        const fileList = info.fileList;
        this.setState({fileList});
    };

    getMenuLists = () => {
        this.setState({loading: true});
        baseService({
            url: '/menus/menusList',
            method: 'get',
        }).then((menus) => {
            if (menus.data.length) {
                this.setState({
                    menuList: menus.data,
                    selectedMenu: menus.data[0].menu_id,
                    selectedMenuName: menus.data[0].menu_name,
                    loading: false
                });
                this.getListByMenu(menus.data[0].menu_id);
            }
        }).catch((error) => console.log(error));
    };

    getListByMenu = (menuId) => {
        this.setState({loading: true});
        baseService({
            url: '/stores/listByMenu',
            method: 'post',
            data: {menu_id: menuId}
        }).then((list) => {
            this.setState({list: list.data, loading: false, storeListChange: list.data})
        }).catch((error) => {
            this.setState({loading: false, list: [], storeListChange: []});
            notification['error']({
                message: 'Menu',
                description: error.response.data.user_msg,
            });
        });
    };

    onChange = (e) => {
        const menu = this.state.menuList.find((menu) => e.target.value === menu.menu_id);
        if (e.target.value !== 'new') {
            this.getListByMenu(e.target.value);
            this.setState({selectedMenu: e.target.value, isNew: false, selectedMenuName: menu && menu.menu_name || ''});
        } else {
            this.setState({
                selectedMenu: e.target.value,
                isNew: true,
                list: [],
                storeListChange: [],
                selectedMenuName: menu && menu.menu_name || ''
            });
        }
    };

    isChecked = (store) => {
        return this.state.list.findIndex((s) => s.uuid === store.uuid) !== -1;
    };

    handleChecked = (event, store) => {
        const {list} = this.state;
        if (event.target.checked) {
            this.setState({list: [...list, store]});
        } else {
            const index = list.findIndex((s) => s.uuid === store.uuid);
            if (index !== -1) {
                list.splice(index, 1);
                this.setState({list: list});
            }
        }
    };

    handleChange = (e) => {
        this.setState({selectedMenuName: e.target.value});
    };


    assignMenuToStore = async () => {
        this.setState({loading: true});
        const {list, storeListChange, selectedMenu} = this.state;
        const requestArray = [];
        list.forEach((store) => {
            const isAlready = storeListChange.find((s) => s.uuid === store.uuid);
            if (!isAlready) {
                requestArray.push(baseService({
                    url: '/stores/createStoreMenu',
                    method: 'post',
                    data: {
                        menu_id: selectedMenu,
                        uuid: store.uuid
                    }
                }))
            }
        })
        if (requestArray.length) {
            let result = await axios.all(requestArray);
            if (result.every(r => r.status === 200)) {
                notification['success']({
                    message: 'Selected store are now have selected menu',
                    description: '',
                });
            } else {
                result.forEach((r, i) => {
                    if (r.status !== 200) {
                        notification['error']({
                            message: 'something went wrong to assign selected menu to' + list[i].name + 'store',
                            description: '',
                        });
                    }
                })
            }
            this.setState({storeListChange: list});
        }
        this.setState({loading: false});
    };

    addMenu = () => {
        let formData = new FormData();
        const {fileList, selectedMenuName, isNew, selectedMenu, menuList} = this.state;

        if (!selectedMenuName) {
            notification['error']({
                message: 'Please add Menu Name!',
                description: '',
            });
        } else if (isNew) {
            if (fileList.length <= 0) {
                notification['error']({
                    message: 'Please select file to add as menu!',
                    description: '',
                });
            } else {
                this.setState({menuLoading: true});
                formData.append('menu_name', selectedMenuName);
                formData.append('menu_body', fileList[0].originFileObj);
                axios({
                    method: 'post',
                    url: `${baseUrl}/menus/addMenu`,
                    data: formData,
                    headers: {'Authorization': 'Bearer ' + getAccessToken()}
                }).then((list) => {
                    notification['success']({
                        message: 'Menu added successfully!',
                        description: '',
                    });
                    this.getListByMenu(list.data.MenuData.menu_id);
                    this.setState({
                        loading: false, menuLoading: false,
                        selectedMenuName: list.data.MenuData.menu_name,
                        selectedMenu: list.data.MenuData.menu_id,
                        menuList: [...this.state.menuList, {
                            menu_id: list.data.MenuData.menu_id,
                            menu_name: list.data.MenuData.menu_name
                        }],
                        fileList: []
                    })
                }).catch((error) => {
                    this.setState({menuLoading: false});
                    notification['error']({
                        message: 'Something went wrong!',
                        description: '',
                    });
                });
            }
        } else {
            this.setState({menuLoading: false});
            formData.append('menu_name', selectedMenuName);
            formData.append('menu_id', selectedMenu);
            if (fileList.length) {
                formData.append('menu_body', fileList[0].originFileObj);
            }
            axios({
                method: 'post',
                url: `${baseUrl}/menus/editMenu`,
                data: formData,
                headers: {'Authorization': 'Bearer ' + getAccessToken()}
            }).then(() => {
                notification['success']({
                    message: 'Menu edited successfully!',
                    description: '',
                });
                const i = menuList.findIndex((menu) => menu.menu_id === selectedMenu);
                menuList[i].menu_name = selectedMenuName;
                this.setState({menuLoading: false, selectedMenuName, menuList});
                this.getListByMenu(selectedMenu);
            }).catch((error) => {
                this.setState({menuLoading: false});
                notification['error']({
                    message: 'Something went wrong!',
                    description: '',
                });
            });
        }
    };

    render() {
        const {menuList, selectedMenu, stores, isNew, selectedMenuName, menuLoading, loading} = this.state;
        const fileProps = {
            onChange: this.handleChangeFile,
            listType: 'text/json',
            showUploadList: false
        };
        const groupByStateAllStore = groupBy(stores, "state");
        return (
            <div className="menu-management">
                <Loader isLoading={loading}/>
                <Loader isLoading={menuLoading}/>
                <div className="menu-content-radio">
                    <h5>
                        Menus
                    </h5>
                    <RadioGroup onChange={this.onChange} value={selectedMenu}>
                        {menuList.length > 0 && menuList.map((menu, index) => (
                            <Radio value={menu.menu_id} key={index}>{menu.menu_name}</Radio>
                        ))}
                        <Radio value='new'>Add a new Menu</Radio>
                    </RadioGroup>
                    <h5 className="mt-3">Menu Details</h5>
                    <div>
                        <div className="file-upload">
                            {this.state.fileList && this.state.fileList.length > 0 &&
                            <p>{this.state.fileList[0].name}</p>} &nbsp;
                            <Upload {...fileProps} fileList={this.state.fileList}>
                                <Button>
                                    <Icon type="upload"/> Upload
                                </Button>
                            </Upload>
                        </div>
                        <div className="counter">Menu Group Name: <Input value={selectedMenuName}
                                                                         onChange={this.handleChange}/></div>
                        <div className="counter">Menu ID: <Input value={isNew ? '' : selectedMenu} disabled={true}/>
                        </div>
                    </div>
                    <div className='save-btn'>
                        <Button type="secondary" className="btn-secondary" onClick={this.addMenu}
                                loading={menuLoading}>{isNew ? 'Save' : 'Save'} Selection</Button>
                    </div>
                </div>
                <div className="menu-content-checkbox">
                    {Object.keys(groupByStateAllStore).map((key) => (
                        <div className="text-block" key={key}>
                            <h5>{key}</h5>
                            <div style={{width: '100%'}}>
                                <Row>
                                    {
                                        groupByStateAllStore[key] && groupByStateAllStore[key].map((store, index) => (
                                            <Col span={8} key={index}><Checkbox checked={this.isChecked(store)}
                                                                                onChange={(e) => this.handleChecked(e, store)}>{store.name}</Checkbox></Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        </div>
                    ))}
                    <div className='save-btn'>
                        <Button type="primary" onClick={this.assignMenuToStore}>Save Selection</Button>
                    </div>
                </div>
            </div>
        )
    }
}
