import React from 'react';
import {Link} from 'react-router-dom';
import './style.css';
import {getAccessToken} from "../../services/utils";
import {baseUrl, baseLoginUrl} from "../../services/baseService";
import {notification} from "antd/lib/index";
import axios from "axios/index";
import Loader from '../Loader';

class BaseHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};
    }

    logout = () => {
        this.setState({loading: true});
        axios({
            method: 'get',
            url: `${baseUrl}/oauth/logout`,
            headers: {'Authorization': 'Bearer ' + getAccessToken()}
        }).then(() => {
            this.setState({loading: false});
            localStorage.removeItem('accessToken');
            localStorage.clear();
            window.location.replace(baseLoginUrl);
        }).catch((error) => {
            this.setState({loading: false});
            notification['error']({
                message: error.toString(),
                description: '',
            });
        });
    };

    render() {
        const url = `http://app-config.guzmanygomez.com/${localStorage.getItem("accessToken")}`;
        const {loading} = this.state;
        return (
            <div>
                <Loader isLoading={loading}/>
                <div className="d-flex flex-column main-header">
                    <div className="header d-flex flex-row">
                        <img src="https://www.guzmanygomez.com/wp-content/uploads/2018/06/logo-gomex-angle.svg"/>
                        <span className="flex-space"/>
                        <button onClick={this.logout}>Logout</button>
                    </div>
                </div>
                <div id="wrapper" className="main-header">
                    <ul className="sidebar navbar-nav">
                        <li className="nav-item">
                            <Link to="/uberEatsHome">UberEats Menu/Time management</Link>
                        </li>
                        <li className="nav-item">
                            <a href={url}>App/Web ordering Time management</a>
                        </li>
                    </ul>
                    <div id="content-wrapper"></div>
                </div>
            </div>
        )
    }
}

export default BaseHome;
