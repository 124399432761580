import React from 'react';
import BaseDay from "../../../common/BaseDay";

export default class Breakfast extends React.Component {
    render() {
        const {daysBreakfast} = this.props;
        return (
            <React.Fragment>
                <div className="base-tab">
                    {daysBreakfast && daysBreakfast.service_availability && daysBreakfast.service_availability.length
                    && daysBreakfast.service_availability.map((timeDetails, index) => (
                        <BaseDay
                            timeDetails={timeDetails}
                            key={index}
                            handleEnable={(val) => this.props.handleEnable(val, index, 'days_hours_breakfast')}
                            handleOpens={(startTime, pos) => this.props.handleOpens(startTime,pos, index, 'days_hours_breakfast')}
                            handleCloses={(endTime, pos) => this.props.handleCloses(endTime,pos, index, 'days_hours_breakfast')}
                            addBlock = {() => this.props.addBlock(index,'days_hours_breakfast')}
                            handleRemove={(i) => this.props.handleRemove(i,index,'days_hours_breakfast')}
                        />
                    ))}
                </div>
            </React.Fragment>
        )
    }
}


