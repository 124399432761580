import React, {Component} from 'react';
import {Router, Switch} from "react-router-dom";
import history from '../src/services/history';
import BaseHome from './components/BaseHome/index';
import Home from './components/Home';
import CreateStore from './components/CreateStore';
import Menu from './components/Menu';
import UberEats from './components/UberEats';
import NewStore from './components/NewStore';
import Login from './components/Login';
import {PrivateRoute, LoginRoute, PublicRoute} from './components/routes/routes';

class App extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <PublicRoute exact path='/' component={BaseHome}/>
                    <PrivateRoute exact path='/uberEatsHome' component={Home}/>
                    <PrivateRoute exact path='/store-details' component={CreateStore}/>
                    <PrivateRoute exact path='/menu' component={Menu}/>
                    <PrivateRoute exact path='/ubereats' component={UberEats}/>
                    <PrivateRoute exact path='/new-store' component={NewStore}/>
                    <LoginRoute path='/login' component={Login} />
                </Switch>
            </Router>
        );
    }
}

export default App;
