import axios from 'axios';
import {getAccessToken} from './utils';

export const baseUrl = 'https://ubereats.guzmanygomez.com:9002/api';
// export const baseUrl = 'http://localhost:9002/api';
export const baseLoginUrl = 'http://dev.driveby.guzmanygomez.com:8084';

const baseService = axios.create({
    baseURL: baseUrl,
    headers: {'Authorization': 'Bearer ' + getAccessToken()}
});

export default baseService;
