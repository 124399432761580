import React from 'react';
import {Select} from 'antd';
import baseService from '../../services/baseService';
import Loader from '../Loader';

import './home.css';

const Option = Select.Option;

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            stores: [],
            selected: ''
        }
    }

    componentWillMount() {
        baseService({
            url: '/stores/storesList',
            method: 'get',
        }).then((response) => {
            this.setState({loading: false, stores: response.data});
        }).catch((error) => {
            localStorage.removeItem('accessToken');
            localStorage.clear();
            this.setState({loading: false});
            this.props.history.push({pathname: '/'});
        });
    }

    handleChange = (value) => {
        this.setState({selected: value});
    };

    redirectStoreDetails = () => {
        const selectedStore = this.state.stores.find((store) => store.uuid === this.state.selected);
        this.props.history.push({pathname: '/store-details', state: selectedStore});
    };

    render() {
        const {stores, selected} = this.state;
        const children = [];
        stores.forEach((store) => {
            children.push(<Option key={store.uuid}>{store.name}</Option>);
        });

        return (
            <div className="container pt-4">
                <Loader isLoading={this.state.loading}/>
                <div className="manage-store mb-5">
                    <h6>Select Store</h6>
                    <Select
                        className="mb-3 mr-3"
                        style={{width: '20%'}}
                        placeholder="Select Stores"
                        onChange={this.handleChange}
                    >
                        {children}
                    </Select>
                    <button className="btn btn-dark btn-sm px-4" onClick={this.redirectStoreDetails}
                            disabled={!selected}>GO
                    </button>
                </div>
            </div>
        )
    }
}
